<template>
  <b-card class="border">
    <b-row>
      <b-col sm="12" lg="6">
        <input-component :label-input="$t('contacts.create.phone')"
                         v-model="$v.form.phone.$model"
                         required
                         :state="validateState('phone') && telState"
                         :require-label="validateState('phone') !== null && !$v.form.phone.required  ? $t('common.requiredField') : !telState ? telInvalid : ''"
        />
      </b-col>
      <b-col sm="12" lg="6">
        <input-component :label-input="$t('contacts.create.email')"
                         v-model="$v.form.email.$model"
                         required
                         :state="validateState('email')"
                         :require-label="validateState('email') !== null && !$v.form.email.required  ? $t('common.requiredField') : ''"
        />
      </b-col>
      <b-col sm="12" lg="6">
        <input-component :label-input="$t('contacts.create.fullName')"
                         v-model="$v.form.first_name.$model"
                         required
                         :state="validateState('first_name')"
                         :require-label="validateState('first_name') !== null && !$v.form.first_name.required  ? $t('common.requiredField') : ''"
        />
      </b-col>
      <b-col sm="12" lg="6">
        <input-component :label-input="$t('contacts.create.lastName')"
                         v-model="$v.form.last_name.$model"
                         required
                         :state="validateState('last_name')"
                         :require-label="validateState('last_name') !== null && !$v.form.last_name.required  ? $t('common.requiredField') : ''"
        />
      </b-col>
      <b-col sm="12" lg="6">
        <input-component v-model="form.username"
                         :label-input="$t('contacts.create.userName')"/>
      </b-col>
      <b-col sm="12" lg="6">
        <input-component v-model="form.company"
                         :label-input="$t('contacts.create.company')"/>
      </b-col>
      <b-col sm="12" lg="12">
        <input-component v-model="form.address"
                         :label-input="$t('contacts.create.address')"/>
      </b-col>
      <b-col sm="12" lg="6">
        <input-component v-model="form.birth_date"
                         :label-input="$t('contacts.create.dateOfBirth')"/>
      </b-col>
      <b-col sm="12" lg="6">
        <input-component v-model="form.anniversary_date"
                         :label-input="$t('contacts.create.dateOfBAnniversary')"/>
      </b-col>
    </b-row>
    <b-row align-h="end" class="mr-1 mt-3">
      <b-button variant="primary"
                :disabled="$v.form.$invalid|| !telState ||is_loading"
                @click="createContact"
      ><i class="fas fa-save"></i>
        {{ $t('button.save') }}
        <b-spinner small v-if="is_loading"/>
      </b-button>
    </b-row>
  </b-card>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import contactApi from "@/repository/contactApi";
import alert from "@/common/alert";
import {required, email, numeric} from "vuelidate/lib/validators";
import functionCommon from "@/common/functions"
import {validationMixin} from "vuelidate";


export default {
  name: "manualCreateContact",
  props: ['group_id'],
  components: {SelectComponent, InputComponent, InputTextAreaComponent, FormWizard, TabContent, UploadFileComponent},
  mixins: [validationMixin],
  data() {
    return {
      is_loading: false,
      form: {
        contact_group_id: this.group_id,
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        company: "",
        address: "",
        birth_date: "",
        anniversary_date: ""
      },
    }
  },
  validations: {
    form: {
      phone: {
        required,
        numeric
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email
      }
    },
  },
  computed: {
    telState() {
      return functionCommon.telState(this.form.phone)
    },
    telInvalid() {
      const data = functionCommon.telInvalid(this.form.phone)
      return this.$t(data)
    },
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    createContact() {
      this.is_loading = true
      contactApi.create(this.form).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.is_loading = false
      })
    },
    clearData() {
      const data = {
        contact_group_id: this.group_id,
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        company: "",
        address: "",
        birth_date: "",
        anniversary_date: ""
      }
      this.form = data
      this.$v.form.$reset()
    }
  }
}
</script>

<style scoped>
.preview-box {
  border: 1px solid #525252;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  background-color: rgba(233, 235, 239, 0.98);
  min-width: 35px;
  min-height: 47px;
}

</style>