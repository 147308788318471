import service from "./index";
import functionsCommon from "@/common/functions";
import moment from "moment/moment";


const resourcePath = '/campaigns'
export default {
    getCampaignAll(date_from, date_to) {
        return service.apiAuth.get(`${resourcePath}/calendar/list?date_from=${date_from}&date_to=${date_to}`)
            .then(resp => {
                let currentEvent = []
                let actualCurrentEvent = []
                resp.data.data.map((event) => {
                    let form = {
                        id: event._id,
                        title: event.name,
                        campaign_type: event.campaign_type,
                        start: event.campaign_start,
                        status: event.campaign_status,
                        contact: event.total_contact,
                        credit: event.total_cost,
                    }
                    currentEvent.push(form);
                })
                for (let i = 0; i <= 23; i++) {
                    const data = {
                        label: i < 10 ? `0${i}:00` : `${i}:00`,
                        data: []
                    }
                    currentEvent.map((value) => {
                        const start_time = moment(new Date(value.start)).format('HH:00')
                        if (start_time === data.label) {
                            data.data.push(value)
                        }
                    })
                    actualCurrentEvent.push(data)
                }
                return actualCurrentEvent
            }).catch(err => {
                throw err
            })
    },
    returnCampaignAll(month, year) {
        const response = service.apiAuth.get(`${resourcePath}/calendar/list?month=${month}&year=${year}`)
            .then((resp) => {
                let currentEvent = []
                resp.data.data.map((event) => {
                    let form = {
                        id: event._id,
                        title: event.name,
                        campaign_type: event.campaign_type,
                        start: event.campaign_start,
                        status: event.campaign_status,
                        contact: event.total_contact,
                        credit: event.total_cost,
                    }
                    currentEvent.push(form);
                })
                return currentEvent
            }).catch(err => {
                throw err
            })
        return response
    },
    getCampaignStatus(form) {
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(form))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/summary-campaigns-status?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })

    },
}