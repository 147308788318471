<template>
  <b-card-text>
    <form-wizard
        :color="secondary_color"
        :title="null"
        :subtitle="null"
        step-size="xs"
    >
      <tab-content :title="$t('contacts.create.importFile')" icon="fas fa-upload">
        <h5>{{ $t('campaigns.usingFileFrom.expFile') }}</h5>
        <b-button-group class="mt-1 mb-3">
          <b-button variant="primary" v-for="(item, index) in example" @click="exampleFile(item.value)" :key="index">
            {{ item.text }}
          </b-button>
        </b-button-group>
        <upload-file-contact-component @path_file="mapPath" @output="showResult" remark-file/>
        <div class="mt-3" v-show="form.path_file">
          <label class="text-bold text-primary">{{ $t('common.exemple') }}</label>
          <hr class="m-0"/>
        </div>
        <b-form-checkbox v-show="form.path_file" v-model="form.isHead" @input="containsHeader" class="mt-2 mb-2">
          {{ $t('campaigns.usingFileFrom.fileContainsHeaderRow') }}
        </b-form-checkbox>
        <div style="overflow-x:auto;" align="center" v-if="form.path_file">
          <table>
            <tr v-for="rowItem in previewList" :key="rowItem.index">
              <td style="min-width: 200px" class="preview-box" v-for="Item in rowItem" :key="Item.index">
                {{ Item }}
              </td>
            </tr>
            <tr v-if="previewList.length > 0">
              <td class="preview-box" v-for="(Item, key , index) in previewList[0]" :key="Item.index">
                <b-form-select
                    :value=null
                    @change="selectedKey(index, key , $event)"
                    :options="headList"
                >
                  <option :value=null>{{$t("common.pleaseSelectOption")}}</option>
                </b-form-select>
              </td>
            </tr>
          </table>
        </div>
      </tab-content>
      <tab-content :title="$t('contacts.create.validate')" icon="fas fa-table">
        <b-row class=" mb-2">
          <b-col
              class="mb-1 pr-1 "
              lg="6" sm="12" md="12"
              v-for="(totals, index) in totalImport"
              :key="index"
          >
            <div class="total-overview " :class="[totals.bgcolor]">
              <div class="d-flex align-items-center p-3">
                <div class="mr-2" style="width: 73px!important;">
                  <span class="display-3 ml-2" :class="[totals.iconcolor]">
                    <i :class="[totals.icon]" class="mt-2"></i>
                  </span>
                </div>
                <div>
                  <h5 :class="[totals.textcolor]" class="text"> {{ $t(totals.text) }}</h5>
                  <h3 class="font-medium mb-0">{{ totals.total }}</h3>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content :title="$t('contacts.create.success')" icon="far fa-check-circle ">
        <div align="center" v-if="success&&step_success">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
        </div>
        <div align="center" v-if="!success&&step_success">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
        <div align="center" v-if="!success&&!step_success">
          <b-spinner
              class="mt-5 mb-1"
              style="width: 4rem; height: 4rem"
              label="Large Spinner"
              variant="warning"
              type="grow">
          </b-spinner>
          <h3 v-html="$t('processing')" class="mb-3"></h3>
        </div>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <b-button v-if="props.activeTabIndex > 0" @click="props.prevTab()">
            <i class="fas fa-chevron-left"/>
            {{ $t('common.previous') }}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button v-if="!props.isLastStep" @click="nextPage(props)" variant="gradient"
                    class="wizard-footer-right width-btn"
                    :disabled="$v.form.$invalid || is_loading_next_step || total_valid === 0 || is_loading_last_step">
            {{ $t('common.next') }}
            <b-spinner small label="Small Spinner" v-if="is_loading_next_step"></b-spinner>
            <i class="fas fa-chevron-right" v-else/>
          </b-button>
          <b-button v-else @click="isLastStep(props)"
                    variant="gradient"
                    :disabled="is_loading_last_step" class="wizard-footer-right width-btn">
            {{ props.isLastStep ? $t('common.done') : $t('common.next') }}
            <b-spinner small label="Small Spinner" v-if="is_loading_last_step"></b-spinner>
            <i class="fas fa-chevron-right" v-else/>
          </b-button>
        </div>
      </template>
    </form-wizard>
  </b-card-text>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ManualCreateContact from "@/views/contacts/contact/manualCreate";
import SelectComponent from "@/components/common/selectComponent";
import InputComponent from "@/components/common/inputComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import _ from "lodash";
import UploadFileContactComponent from "@/components/common/uploadFileContactComponent";
import contactApi from "@/repository/contactApi";
import alert from "@/common/alert";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {mapState} from 'vuex'

export default {
  name: "importFileContact",
  props: ['group_id'],
  mixins: [validationMixin],
  components: {
    UploadFileContactComponent,
    ManualCreateContact,
    SelectComponent, InputComponent, InputTextAreaComponent, FormWizard, TabContent
  },
  data() {
    return {
      baseURL: '/api',
      status: null,
      success: true,
      step_success: true,
      form: {
        path_file: '',
        isHead: false,
        phone: '',
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        company: '',
        address: '',
        birth_date: '',
        anniversary_date: '',
      },

      testResult: {},
      originalFile: [],
      displayTable: false,
      resultList: [],
      headList: [],
      is_next_step: false,
      is_loading_last_step: false,
      is_loading_next_step: false,
      total_valid: null,
      example: [
        {
          is_loading: false,
          value: 'xls',
          text: '.XLS'
        },
        {
          is_loading: false,
          value: 'csv',
          text: '.CSV'
        },
        {
          is_loading: false,
          value: 'xlsx',
          text: '.XLSX'
        },
        {
          is_loading: false,
          value: 'txt',
          text: '.TXT'
        }
      ],
      totalImport: [
        {
          bgcolor: "total-overview-purple",
          iconcolor: "",
          icon: "fas fa-users",
          textcolor: "text-gray",
          text: "contacts.import.totalContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-green",
          iconcolor: "",
          icon: "fas fa-check-circle",
          textcolor: "text-gray",
          text: "contacts.import.validContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-gray",
          iconcolor: "",
          icon: "far fa-clone",
          textcolor: "text-gray",
          text: "contacts.import.duplicate",
          total: "0",
        },
        {
          bgcolor: "total-overview-red",
          iconcolor: "",
          icon: "far fa-times-circle",
          textcolor: "text-gray",
          text: "contacts.import.invalidContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-warning",
          iconcolor: "",
          icon: "far fa-thumbs-down",
          textcolor: "text-gray",
          text: "contacts.import.spamContact",
          total: "0",
        },

      ],
    }
  },
  validations: {
    form: {
      phone: {required},
      path_file: {required},
    }
  },
  computed: {
    ...mapState({
      secondary_color: state => state.theme.secondaryColor,
    }),
    previewList() {
      const data = _.chunk(Object.values(this.resultList), 5)
      return data[0] ? data[0] : []
    },
    tagList() {
      return this.$store.getters["template/getHeaderList"];
    },
  },
  methods: {
    exampleFile(type) {
      if (type === 'txt') {
        window.open(this.baseURL + '/resource/example-contact-txt')
      } else {
        contactApi.exampleFileDownload(type).then(resp => {
          if (resp) {
            const link = document.createElement("a");
            link.href = resp.file_url;
            link.download = 'example-contact-file.' + type;
            link.click();
          }
        }).catch(err => {
          throw err
        })
      }

    },
    onComplete() {
      this.$emit('output', 'success');
      this.$bvModal.hide('modal-create-contact')
    },
    containsHeader() {
      if (this.form.isHead) {
        this.originalFile = _.cloneDeep(this.resultList)
        this.resultList.shift()
      } else {
        this.resultList = this.originalFile
      }
    },
    mapPath(e) {
      this.form.path_file = e

    },
    showResult(e) {
      this.total_valid = null
      this.displayTable = true
      this.resultList = e
      this.headList = _.cloneDeep(this.tagList)
      this.clearData()
    },
    clearData() {
      this.form = {
        path_file: '',
        isHead: false,
        phone: '',
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        company: '',
        address: '',
        birth_date: '',
        anniversary_date: '',
      }
      this.$v.form.$reset()
    },
    selectedKey(index, key, e) {
      this.total_valid = null
      let myArray = _.cloneDeep(this.headList)
      const indexValue = key.toString()
      const findDuplicate = myArray.map(e => e.value).indexOf(e);
      if (findDuplicate < 0 && e !== null) {
        const data = {
          value: e,
          text: e
        }
        this.headList.splice(0, 0, data);
      } else {
        if (e !== null) {
          this.headList.splice(findDuplicate, 1);
          const data = {
            value: e,
            text: e,
            disabled: true
          }
          this.unDisableKey(indexValue)
          switch (e) {
            case 'first_name':
              this.form.first_name = indexValue;
              break;
            case 'last_name':
              this.form.last_name = indexValue;
              break;
            case 'phone':
              this.form.phone = indexValue;
              break;
            case 'email':
              this.form.email = indexValue;
              break;
            case 'username':
              this.form.username = indexValue;
              break;
            case 'company':
              this.form.company = indexValue;
              break;
            case 'address':
              this.form.address = indexValue;
              break;
            case 'birth_date':
              this.form.birth_date = indexValue;
              break;
            case 'anniversary_date':
              this.form.anniversary_date = indexValue;
              break;
          }
          this.headList.splice(findDuplicate, 0, data);
        } else {
          this.unDisableKey(indexValue)
        }
      }
    },
    unDisableKey(index) {
      if (!_.isUndefined(this.getObjKey(this.form, index))) {
        const disableKey = this.getObjKey(this.form, index)
        const findKey = this.headList.map(e => e.value).indexOf(disableKey);
        this.headList.splice(findKey, 1);
        let data = {
          value: disableKey,
          text: disableKey
        }
        this.headList.splice(findKey, 0, data);
        this.form[this.getObjKey(this.form, index)] = ''
      }
    },
    getObjKey(obj, value) {
      return Object.keys(obj).find(key => obj[key] === value);
    },
    nextPage(props) {
      this.is_loading_next_step = true
      if (props.activeTabIndex === 0) {
        this.success = null
        this.form.contact_group_id = this.group_id
        contactApi.validateFile(this.form).then(response => {
          if (response?.codeSYS === '001') {
            this.totalImport[0].total = response?.data?.total
            this.totalImport[1].total = response?.data?.total_valid
            this.totalImport[2].total = response?.data?.total_duplicate
            this.totalImport[3].total = response?.data?.total_invalid
            this.totalImport[4].total = response?.data?.total_spam
            this.total_valid = response?.data?.total_valid
            props.nextTab()
          } else {
            alert.addNewFailed(this, response.message)
          }
        }).catch(err => {
          alert.addNewFailed(this, err.response?.data?.message)
        }).finally(() => {
          this.is_loading_next_step = false
        })
      } else if (props.activeTabIndex === 1) {
        this.is_loading_next_step = true
        this.step_success = false
        this.form.contact_group_id = this.group_id
        contactApi.importAdd(this.form).then(response => {
          if (response?.codeSYS === '001') {
            this.step_success = true
            this.success = true
            props.nextTab()
          } else {
            this.step_success = true
            this.success = false
          }
        }).catch(err => {
          this.step_success = true
          this.success = false
        }).finally(() => {
          this.is_loading_next_step = false
        })
        props.nextTab()
      } else {
        props.nextTab()
      }
    },
    isLastStep(props) {
      if (props.isLastStep) {
        this.clearData()
        this.$emit('output-import', 'success');
      }
    },
  }
}
</script>

<style scoped>
.preview-box {
  border: 1px solid #525252;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  background-color: rgba(233, 235, 239, 0.98);
  width: 35px;
  min-height: 47px;
}

.total-overview {
  color: #fff;
  border-radius: 15px;
  background-repeat: repeat-x;
}

.total-overview-green {
  background: #0064fa;
  background-image: linear-gradient(30deg, rgba(40, 199, 111, .5), #28c76f);
}

.total-overview-red {
  background: #640064;
  background-image: linear-gradient(30deg, #ea5455, rgba(234, 84, 85, .5));
}

.total-overview-warning {
  background: #ff8730;
  background-image: linear-gradient(30deg, rgb(241, 199, 70), #eeb559);
}

.total-overview-purple {
  background: #640064;
  background-image: linear-gradient(30deg, #7367f0, rgba(115, 103, 240, .5));
}

.total-overview-gray {
  background: rgba(180, 180, 185, 0.99);
  background-image: linear-gradient(30deg, rgb(148, 149, 155), #525252);
}

.display-3 {
  font-size: 2.75rem;
}

.label-for-topic-over {
  color: #0d73d9;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>