import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = "/sms-templates";
export default {
    findSMSTemplateAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit;
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`${resourcePath}/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getTag(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit;
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(search)
        );
        const str = queryString.toString();
        return service.apiAuth
            .get(`tag-templates/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getById(id) {
        return service.apiAuth
            .get(`${resourcePath}/get/${id}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    update(id, data) {
        return service.apiAuth
            .patch(`${resourcePath}/update/${id}`, data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    create(data) {
        return service.apiAuth
            .post(`${resourcePath}/create`, data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    delete(id) {
        return service.apiAuth
            .delete(`${resourcePath}/delete/${id}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    activeStatus(id, data) {
        return service.apiAuth
            .patch(`${resourcePath}/active/${id}`, data)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
};