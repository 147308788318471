<template>
  <b-modal
      id="modal-view-contact"
      size="lg"
      ok-variant="info"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('contacts.view.updateContact') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
    </template>
    <b-card class="border">
      <b-row>
        <b-col sm="12" lg="6">
          <input-component :label-input="$t('contacts.create.phone')"
                           v-model="$v.form.phone.$model"
                           required max="11"
                           :state="validateState('phone') && telState"
                           :require-label="validateState('phone') !== null && !$v.form.phone.required  ? $t('common.requiredField') : !telState ? telInvalid : ''"
          />
        </b-col>
        <b-col sm="12" lg="6">
          <input-component :label-input="$t('contacts.create.email')"
                           v-model="$v.form.email.$model"
                           required
                           :state="validateState('email')"
                           :require-label="validateState('email') !== null && !$v.form.email.required  ? $t('common.requiredField') : ''"
          />
        </b-col>
        <b-col sm="12" lg="6">
          <input-component :label-input="$t('contacts.create.fullName')"
                           v-model="$v.form.first_name.$model"
                           required
                           :state="validateState('first_name')"
                           :require-label="validateState('first_name') !== null && !$v.form.first_name.required  ? $t('common.requiredField') : ''"
          />
        </b-col>
        <b-col sm="12" lg="6">
          <input-component :label-input="$t('contacts.create.lastName')"
                           v-model="$v.form.last_name.$model"
                           required
                           :state="validateState('last_name')"
                           :require-label="validateState('last_name') !== null && !$v.form.last_name.required  ? $t('common.requiredField') : ''"
          />
        </b-col>
        <b-col sm="12" lg="6">
          <input-component v-model="form.username"
                           :label-input="$t('contacts.create.userName')"/>
        </b-col>
        <b-col sm="12" lg="6">
          <input-component v-model="form.company"
                           :label-input="$t('contacts.create.company')"/>
        </b-col>
        <b-col sm="12" lg="12">
          <input-component v-model="form.address"
                           :label-input="$t('contacts.create.address')"/>
        </b-col>
        <b-col sm="12" lg="6">
          <input-component v-model="form.birth_date"
                           :label-input="$t('contacts.create.dateOfBirth')"/>
        </b-col>
        <b-col sm="12" lg="6">
          <input-component v-model="form.anniversary_date"
                           :label-input="$t('contacts.create.dateOfBAnniversary')"/>
        </b-col>
      </b-row>
      <b-row align-h="end" class="mr-1 mt-3">
        <b-button variant="primary"
                  :disabled="$v.form.$invalid|| !telState"
                  @click="updateContact"
        ><i class="fas fa-save"></i> {{ $t('button.save') }}
        </b-button>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import {email, numeric, required} from "vuelidate/lib/validators";
import contactApi from "@/repository/contactApi";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import functionCommon from "@/common/functions";
import moment from "moment";


export default {
  name: "viewContact",
  components: {SelectComponent, InputComponent, InputTextAreaComponent, UploadFileComponent},
  mixins: [validationMixin],
  props: ['contact_id'],
  data() {
    return {
      form: {
        contact_group_id: this.group_id,
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        company: "",
        address: "",
        birth_date: "",
        anniversary_date: ""
      },
    }
  },
  validations: {
    form: {
      phone: {
        required,
        numeric
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email
      }
    },
  },
  created() {
    this.$watch('contact_id', (val) => {
      if (val) {
        this.getContactById()
      }
    })
  },
  computed: {
    telState() {
      return functionCommon.telState(this.form.phone)
    },
    telInvalid() {
      const data = functionCommon.telInvalid(this.form.phone)
      return this.$t(data)
    },
  },
  methods: {
    getContactById() {
      contactApi.getById(this.contact_id).then(resp => {
        this.form.phone = resp.phone
        this.form.first_name = resp.first_name
        this.form.last_name = resp.last_name
        this.form.email = resp.email
        this.form.username = resp.username
        this.form.company = resp.company
        this.form.address = resp.address
        this.form.birth_date = resp.birth_date
        this.form.anniversary_date = resp.anniversary_date
      }).catch(err => {
        throw err
      })
    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    updateContact() {
      contactApi.update(this.form, this.contact_id).then(response => {
        if (response?.codeSYS === '001') {
          this.getContactById()
          this.clearData()
          alert.updateSuccess(this)
        } else {
          alert.updateFailed(this, response.message)
        }
        this.$v.form.$reset()
      }).catch(err => {
        alert.updateFailed(this, err.response?.data?.message)
      })
    },
    clearData() {
      this.$v.form.$reset()
      this.$emit('output', 'success');
      this.$bvModal.hide('modal-view-contact')
    }
  }
}
</script>

<style scoped>

</style>