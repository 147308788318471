<template>
  <div class="calendar-container" id="calendar__container-wrap"
       v-if="checkPermission('list-summary', 'action', 'calendar')">
    <topic-component :topic="$t('SideBarItems.calendar.text')"></topic-component>
    <div class="full-calendar">
      <div class="full-calendar__calendar-nav">
        <div class="nav-calendar__filter-calendar">
          <div class="filter-calendar__left">
            <button
                class="btn"
                style="width: 100%"
                :class="{'btn-primary':focus_format_month, 'btn-no-outline':!focus_format_month}"
                @click="changeViewToMonth">
              {{ $t("button.month") }}
            </button>
            <button
                class="btn"
                style="width: 100%"
                :class="{'btn-primary':focus_format_day, 'btn-no-outline':!focus_format_day}"
                @click="changeViewToDay(false,'')">
              {{ $t("button.day") }}
            </button>
          </div>
          <div class="filter-calendar__right" :class="{'filter-calendar__right-day':time_grid_day}">
            <div class="filter-calendar">
              <select-filter-component
                  :options="filter_month"
                  :block_first_template="filter_month.length > 0 "
                  v-model="form_filter.select_filter_month"
                  v-if="!time_grid_day"
              />
              <select-filter-component
                  :options="filter_year"
                  :block_first_template="filter_month.length > 0 "
                  v-model="form_filter.select_filter_year"
                  v-if="!time_grid_day"
              />
              <b-button
                  variant="gradient"
                  v-if="!time_grid_day"
                  @click="navigateFilerByMonthAndYear"
              >
                {{ $t("common.Search") }}
              </b-button>
            </div>
            <button @click="navigateAddNewCampaign" v-if="checkPermission('campaign-builder', 'tab')"
                    class="add-new-campaign btn btn-outline">
              <i class="fas fa-plus-circle "></i>
              {{ $t("button.addNewCampaign") }}
            </button>
          </div>
        </div>
        <div class="nav-calendar__calendar-operate">
          <span class="current-date">{{ current_view_title }}</span>
          <div class="calendar-prev-next">
            <div class="calendar-prev" @click="!prev_icon_disable&&prev()"
                 :style="`${iconMaskURL('prev')} ${prev_icon_disable?'background-color:#9F9F9F':''}`">
            </div>
            <span class="current-date-sm">{{ current_view_title }}</span>
            <div class="calendar-next" @click="!next_icon_disable&&next()"
                 :style="`${iconMaskURL('next')} ${next_icon_disable?'background-color:#9F9F9F':''}`">
            </div>
          </div>
          <b-button variant="primary" @click="today" :disabled="is_today" v-if="!time_grid_day">
            {{ $t("button.today") }}
          </b-button>
          <b-button variant="primary" @click="scrollToThisTime" v-else :disabled="is_day_today ">
            {{ $t("button.atThisTime") }}
          </b-button>
        </div>
        <div class="nav-calendar__calendar-status">
          <div class="calendar-status__layout">
            <div class="calendar-status__status-total">
              <span class="total-campaign">
                {{ $t("calendar.total") + ` (` + $t("calendar.campaign") + `)` + ` ` + `:` }}
                {{ total_all }}
              </span>
            </div>
            <div class="calendar-status__layout-layout">
              <div class="calendar-status">
                <div class="calendar-status__status">
                  <img
                      src="@/assets/images/icon/wait-for-sending.svg"
                      alt="wait for sending"
                  />
                  <span>{{ $t("calendar.waitUntilSending") + ` : ` }} {{ total_sending }}</span>
                </div>
                <div class="calendar-status__status">
                  <img
                      src="@/assets/images/icon/pending-calendar-status.svg"
                      alt="pending"
                  />
                  <span> {{ $t("calendar.pending") + ` : ` }} {{ total_pending }}</span>
                </div>
                <div class="calendar-status__status">
                  <img
                      src="@/assets/images/icon/sent-calendar-status.svg"
                      alt="sent"
                  />
                  <span>{{ $t("calendar.sent") + ` : ` }} {{ total_sent }}</span>
                </div>
                <div class="calendar-status__status">
                  <img
                      src="@/assets/images/icon/cancel-calendar-status.svg"
                      alt="cancel"
                  />
                  <span>
                  {{ $t("calendar.canceledByCustomer") + ` : ` }}
                  {{ total_cancelled }}
                </span>
                </div>
              </div>
              <button @click="navigateReportCampaign"
                      v-if="checkPermission('list-campaign', 'action', 'campaign-reports')"
                      class="report-campaign btn btn-outline">
                {{ $t("SideBarItems.reports.list.reportCampaigns") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div :class="{'calendar-hidden-container':time_grid_day}" class="calendar-block-container">
        <div class="loading-calendar text-primary my-2" id="loading-calendar">
          <b-spinner class="mr-2"></b-spinner>
          <strong> {{ $t('loading') }} </strong>
        </div>
        <div id="calendar-opacity-loading-container" class="calendar-opacity-loading-container">
        </div>
        <FullCalendar ref="full_calendar" :options="calendarOptions">
          <template v-slot:eventContent="arg">
            <div :class="`${checkFormat()}`" class="event-detail" v-if="day_grid_month">
              <tippy
                  :animate-fill="false"
                  interactive
                  placement="right"
                  theme="light"
                  animation="shift-toward"
                  trigger="click"
              >
                <template v-slot:trigger>
                  <span :id="`event-tooltip-${arg.event.id}`" class="custom-tooltip-content">
                    <img :src="checkEventColor(arg.event?.extendedProps?.status)" alt="status">
                    <span>{{ format(arg.event.start, "HH:mm") }}</span>
                    <span>{{ arg.event.title }}</span>
                  </span>
                </template>
                <div class="icon-status-container">
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('megaphone')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <p style="text-align: left; overflow-wrap: anywhere;">
                        {{ temp_show_event?.campaign_name }}</p>
                    </div>
                  </div>
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-type')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <p> {{ temp_show_event?.campaign_type }} </p>
                    </div>
                  </div>
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-start')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <span> {{ temp_show_event?.start }} <p
                          style="color: #9F9F9F !important;">(Time to send)</p></span>
                    </div>
                  </div>
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('contacts')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <p>{{ `Contact : ` }}{{ temp_show_event?.total_contract }} </p>
                    </div>
                  </div>
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-credit')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <p>{{ `Credit : ` }} {{ temp_show_event?.total_credit }} </p>
                    </div>
                  </div>
                  <div>
                    <div class="fix-icon-size">
                      <span class="custom-tooltip-icon" :style="iconMaskURL('charging-circle')"></span>
                    </div>
                    <div class="fix-icon-paragraph">
                      <span>
                          <img :src="checkEventColor(temp_show_event?.status)" alt="status">
                      <p>
                        {{
                          temp_show_event?.status === 'schedule' || temp_show_event?.status === 'new' ? 'Wait for delivery time' : temp_show_event?.status
                        }}
                      </p>
                      </span>
                    </div>
                  </div>
                </div>
              </tippy>
            </div>
          </template>
        </FullCalendar>
      </div>
      <div :class="{'calendar-hidden-container-day':!time_grid_day}" class="calendar-block-container">
        <div class="loading-calendar-day text-primary my-2" id="loading-calendar-day">
          <b-spinner class="mr-2"></b-spinner>
          <strong> {{ $t('loading') }} </strong>
        </div>
        <div id="calendar-opacity-loading-container-day" class="calendar-opacity-loading-container-day">
        </div>
        <div class="time_grid_day-container">
          <div class="grid-header" :class="{'high-lighted':checkHighLigthed(time - 1)}" v-for="time in 24"
          ><span>{{ formatTime(time - 1) }}</span>
          </div>
          <div class="grid-content" v-for="(each_data,index) in temp_event" :key="`grid_content_${index}`">
            <div class="grid-content__row">
              <div v-for="event in each_data.data"
                   @click="campaignDetail(event)"
                   :key="event.id"
                   :id="filterEventDayTime(event.start, each_data.label)?'scroll_high_ligthed':'unscroll_high_ligthed'">
                <div class="event-day">
                  <div class="event-day__row">
                    <img :src="checkEventColor(event.status)" alt="status">
                    <div v-b-tooltip.hover :title="event.title"
                         :disabled="!checkEventTitleDaySize(`${event.title}_${index}`)">
                      <span class="hidden-title-event-day" :id="`${event.title}_${index}`">{{ event.title }}</span>
                    </div>
                  </div>
                  <div class="event-day__row">
                    <div>
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-type')"></span>
                      <p>Campaign type</p>
                    </div>
                    <span> {{ event.campaign_type }} </span>
                  </div>
                  <div class="event-day__row">
                    <div>
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-start')"></span>
                      <p>Time to send</p>
                    </div>
                    <span> {{ format(new Date(event.start), "HH:mm") }}  </span>
                  </div>
                  <div class="event-day__row">
                    <div>
                      <span class="custom-tooltip-icon" :style="iconMaskURL('contacts')"></span>
                      <p>Contact</p>
                    </div>
                    <span> {{ changeNumberFormat(event.contact) }} </span>
                  </div>
                  <div class="event-day__row">
                    <div>
                      <span class="custom-tooltip-icon" :style="iconMaskURL('camp-credit')"></span>
                      <p>Credit</p>
                    </div>
                    <span> {{ changeNumberFormat(event.credit) }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {format} from "date-fns";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import TopicComponent from "@/components/common/topicComponent.vue";
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import calendarAPI from "@/repository/apiCalendar"
import Contact from "@/views/contacts/contact/index.vue";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import moment from "moment/moment";

export default {
  components: {
    Contact,
    SelectFilterComponent,
    TopicComponent,
    FullCalendar
  },
  data() {
    return {
      window: {
        width: 0
      },
      form_filter: {
        select_filter_month: null,
        select_filter_year: null
      },
      temp_stay_date: null,
      temp_stay_format: null,
      prev_icon_disable: false,
      next_icon_disable: false,
      total_sending: 0,
      total_pending: 0,
      total_sent: 0,
      total_cancelled: 0,
      total_all: 0,
      temp_event: null,
      temp_show_event: null,
      day_grid_month: false,
      time_grid_day: false,
      filter_month_var: [],
      filter_year_var: [],
      is_today: null,
      is_day_today: false,
      focus_format_day: false,
      focus_format_month: true,
      start_of_range: moment(new Date(new Date().getFullYear() - 1, 0, 1)).format('YYYY-MM-DD'),
      end_of_range: moment(new Date(new Date().getFullYear() + 1, 11, 31)).format('YYYY-MM-DD'),
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        timeZone: 'UTC',
        headerToolbar: false,
        initialView: 'dayGridMonth',
        editable: false,
        selectable: true,
        selectMirror: true,
        validRange: {
          start: moment(new Date(new Date().getFullYear() - 1, 0, 1)).format('YYYY-MM-DD'),
          end: moment(new Date(new Date().getFullYear() + 1, 11, 32)).format('YYYY-MM-DD')  // has bug in lib date decrease 1 day
        },
        ////// declare end date on month format
        forceEventDuration: true,
        defaultTimedEventDuration: '00:00:00',
        //////
        events: function (fetchInfo, successCallback, failureCallback) {
          let start = new Date(fetchInfo.start)
          let end = new Date(fetchInfo.end)
          let actual_month = null
          let actual_year = null
          start.getDate() !== 1 ? actual_month = start.getMonth() + 2 : actual_month = start.getMonth() + 1
          if (start.getFullYear() !== end.getFullYear()) {
            if ((start.getMonth() + 1 === 11 || start.getMonth() + 1 === 12) && end.getMonth() + 1 === 1) {
              if (start.getMonth() + 1 === 11) {
                actual_month = start.getMonth() + 2
              }
              if (start.getMonth() + 1 === 12) {
                actual_month = start.getMonth() + 1
              }
              actual_year = start.getFullYear()
            } else {
              if (start.getFullYear() !== end.getFullYear()) {
                if (start.getMonth() + 1 === 12) {
                  actual_month = 1
                  actual_year = end.getFullYear()
                }
              } else {
                actual_month = start.getMonth() + 1
                actual_year = end.getFullYear()
              }
            }
          } else {
            actual_year = end.getFullYear()
          }
          document.getElementById('calendar-opacity-loading-container').style.display = 'block'
          document.getElementById('loading-calendar').style.display = 'flex'
          calendarAPI.returnCampaignAll(actual_month, actual_year).then((res) => {
            successCallback(res)
          }).catch((e) => {
            throw e
          }).finally(() => {
            document.getElementById('calendar-opacity-loading-container').style.display = 'none'
            document.getElementById('loading-calendar').style.display = 'none'
          })
        },
        dayMaxEvents: 3,
        weekends: true,
        eventClick: this.handleEventClick,
        moreLinkClick: this.handleClickMoreLinkAndDate,
        dateClick: this.handleClickMoreLinkAndDate,
      },
      full_calendar: null,
      calendar_api: null,
      current_view_title: null,
    }
  },
  computed: {
    filter_month() {
      let month_number = new Date().getMonth()
      this.form_filter.select_filter_month = masterData.monthList[month_number].value
      this.filter_month_var = masterData.monthList
      return this.filter_month_var
    },
    filter_year() {
      this.filter_year_var.push(
          {
            value: new Date().getFullYear() - 1,
            en: new Date().getFullYear() - 1,
            th: new Date().getFullYear() - 1,
            cn: new Date().getFullYear() - 1,
            id: new Date().getFullYear() - 1,
            my: new Date().getFullYear() - 1,
            vn: new Date().getFullYear() - 1,
          },
          {
            value: new Date().getFullYear(),
            en: new Date().getFullYear(),
            th: new Date().getFullYear(),
            cn: new Date().getFullYear(),
            id: new Date().getFullYear(),
            my: new Date().getFullYear(),
            vn: new Date().getFullYear(),
          },
          {
            value: new Date().getFullYear() + 1,
            en: new Date().getFullYear() + 1,
            th: new Date().getFullYear() + 1,
            cn: new Date().getFullYear() + 1,
            id: new Date().getFullYear() + 1,
            my: new Date().getFullYear() + 1,
            vn: new Date().getFullYear() + 1
          }
      )
      this.form_filter.select_filter_year = this.filter_year_var[1].value
      return this.filter_year_var
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getAllCampaignStatus({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    })
    this.getAllCampaignEvent(new Date())
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  updated() {
    this.changeLanguage()
    this.isToday()
    this.formatCalendarTitle()
    this.temp_stay_format = this.calendar_api?.currentData.currentViewType
    this.temp_stay_date = moment(this.calendar_api.currentData.currentDate).format('YYYY-MM-DD').toString()
    let time_grid_day_container = document.querySelector("div.time_grid_day-container")
    if (this.calendar_api?.currentData.currentViewType === "dayGridMonth") {
      time_grid_day_container.removeEventListener('scroll', this.checkScrollToTime)
      this.checkRangeOfCalendar('month')
      this.day_grid_month = true
    } else {
      if (time_grid_day_container) {
        time_grid_day_container.addEventListener('scroll', this.checkScrollToTime)
        this.checkScrollToTime()
        if (!this.checkIsCurrentDate()) {
          this.is_day_today = false
        }
      }
      this.checkRangeOfCalendar('day')
      this.day_grid_month = false
      this.time_grid_day = true
      let remove_border = document.querySelectorAll('div.time_grid_day-container div.grid-content')
      remove_border[remove_border.length - 1].style.borderRight = 'transparent'
    }
    this.setupCalendarLayout()
    this.calendar_api?.render()
  },
  mounted() {
    this.calendar_api = this.$refs.full_calendar?.getApi()
    this.changeLanguage()
    this.setupCalendarLayout()
    this.isToday()
  },
  methods: {
    format,
    checkEventTitleDaySize(e) {
      if (this.time_grid_day && e) {
        let element = document.getElementById(e)
        if (element) {
          return element.offsetWidth === 100
        }
      }
      return false
    },
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '0'
    },
    iconMaskURL(name) {
      return functionsCommon.iconMaskURL(name)
    },
    checkIsCurrentDate() {
      let current_select = moment(this.calendar_api.currentData.currentDate).format('YYYY-MM-DD').toString()
      let current_date = moment(new Date()).format('YYYY-MM-DD').toString()
      return current_select === current_date
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    checkRangeOfCalendar(type) {
      let current_date, start_of_range, end_of_range
      if (type === 'month') {
        current_date = moment(new Date(this.calendar_api?.currentData.currentDate)).format('YYYY-MM').toString()
        start_of_range = moment(new Date(this.start_of_range)).format('YYYY-MM').toString()
        end_of_range = moment(new Date(this.end_of_range)).format('YYYY-MM').toString()
      } else if (type === 'day') {
        current_date = moment(new Date(this.calendar_api?.currentData.currentDate)).format('YYYY-MM-DD').toString()
        start_of_range = moment(new Date(this.start_of_range)).format('YYYY-MM-DD').toString()
        end_of_range = moment(new Date(this.end_of_range)).format('YYYY-MM-DD').toString()
      }
      if (current_date === start_of_range) {
        this.prev_icon_disable = true
        this.next_icon_disable = false
        document.querySelector('.calendar-prev').style.cursor = 'not-allowed';
        document.querySelector('.calendar-next').style.cursor = 'pointer';
      } else if (current_date === end_of_range) {
        this.prev_icon_disable = false
        this.next_icon_disable = true
        document.querySelector('.calendar-next').style.cursor = 'not-allowed';
        document.querySelector('.calendar-prev').style.cursor = 'pointer';
      } else {
        this.prev_icon_disable = false
        this.next_icon_disable = false
        document.querySelector('.calendar-prev').style.cursor = 'pointer';
        document.querySelector('.calendar-next').style.cursor = 'pointer';
      }
    },
    formatCalendarTitle() {
      if (this.calendar_api?.currentData.currentViewType === "dayGridMonth") {
        if (this.$i18n.locale === "th") {
          let title = this.calendar_api?.currentData.viewTitle.split(' ');
          this.current_view_title = title[0] + ' ' + this.format(this.calendar_api.currentData.currentDate, "yyyy")
        } else {
          this.current_view_title = this.calendar_api?.currentData.viewTitle
        }
      } else if (this.calendar_api?.currentData.currentViewType === "timeGridDay") {
        if (this.$i18n.locale === "th") {
          let title = this.calendar_api?.currentData.viewTitle.split(' ');
          this.current_view_title = title[0] + ' ' + title[1] + ' ' + this.format(this.calendar_api.currentData.currentDate, "yyyy")
        } else if (this.$i18n.locale === "en") {
          let title = this.calendar_api?.currentData.viewTitle.split(' ');
          const noSpecialCharacters = title[1].replace(/[^a-zA-Z0-9 ]/g, '');
          this.current_view_title = noSpecialCharacters + ' ' + title[0] + ' ' + title[2]
        } else {
          this.current_view_title = this.calendar_api?.currentData.viewTitle
        }
      }
    },
    checkScrollToTime() {
      let time_grid_day_container = document.querySelector("div.time_grid_day-container"),
          target = time_grid_day_container.querySelector('div.grid-header.high-lighted')
      if (!_.isNull(target)) { //Now this element stay on viewpoint or not
        if (this.checkIsCurrentDate()) {
          if (!this.isInViewport(target)) {
            this.is_day_today = false
          } else {
            this.is_day_today = true
          }
        } else {
          this.is_day_today = false
        }
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      let grid_height = document.querySelectorAll('.fc-daygrid-day-frame.fc-scrollgrid-sync-inner')
      let calendar_date_title = document.querySelectorAll('div.fc-daygrid-day-top')
      if (this.window.width > 576) {
        grid_height.forEach((e) => {
          e.style.height = `200px`
        })
        this.calendarOptions.dayMaxEvents = 3
      } else {
        calendar_date_title.forEach((e) => {
          e.style.fontSize = `14px`
        })
        grid_height.forEach((e) => {
          e.style.height = `100px`
        })
        this.calendarOptions.dayMaxEvents = 0
      }
    },
    navigateAddNewCampaign() {
      sessionStorage.setItem('_a_menu_sms', 'campaignBuilder')
      this.$router.push({path: 'SMS'})
    },
    navigateReportCampaign() {
      let date_campaign = {
        date_to: null,
        date_form: null
      }
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      if (this.calendar_api?.currentData.currentViewType === "dayGridMonth") {
        date_campaign = {
          time1: moment(new Date(current_date.getFullYear(), current_date.getMonth(), 1)).format('YYYY-MM-DD') + 'T00:00:00',
          time2: moment(new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0)).format('YYYY-MM-DD') + 'T23:59:59',
        }
      } else if (this.calendar_api?.currentData.currentViewType === "timeGridDay") {
        date_campaign = {
          time1: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T00:00:00',
          time2: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T23:59:59',
        }
      }
      const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(date_campaign))
      const str = queryString.toString()
      this.$router.push(`/Reports/Campaigns?${str}`).catch(() => {
      })
    },
    isToday() {
      let today = format(new Date(), "PPPP")
      today === format(this.calendar_api?.currentData.currentDate, "PPPP") ? this.is_today = true : this.is_today = false
    },
    scrollToHighLigth() {
      let time_grid_day_container = document.querySelector("div.time_grid_day-container"),
          target = time_grid_day_container.querySelector('#scroll_high_ligthed')
      if (!_.isNull(target)) {
        target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
      }
    },
    scrollToThisTime() {
      if (this.checkIsCurrentDate()) {
        let time_grid_day_container = document.querySelector("div.time_grid_day-container"),
            target = time_grid_day_container.querySelector('div.grid-header.high-lighted')
        target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
      } else {
        this.today()
        setTimeout(() => {
          let time_grid_day_container = document.querySelector("div.time_grid_day-container"),
              target = time_grid_day_container.querySelector('div.grid-header.high-lighted')
          target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }, 300)
      }
    },
    checkPermission(permission, type, modules) {
      return functionsCommon.userCheckPermission(permission, type, modules)
    },
    checkEventColor(status) {
      let selectColor = {
        new: require('@/assets/images/icon/wait-for-sending.svg'),
        schedule: require('@/assets/images/icon/wait-for-sending.svg'),
        sending: require('@/assets/images/icon/wait-for-sending.svg'),
        cancelled: require('@/assets/images/icon/cancel-calendar-status.svg'),
        processing: require('@/assets/images/icon/pending-calendar-status.svg'),
        delivered: require('@/assets/images/icon/sent-calendar-status.svg')
      }
      return selectColor[status]
    },
    changeLanguage() {
      this.$i18n.locale === "en" && this.calendar_api.setOption('locale', 'en');
      this.$i18n.locale === "th" && this.calendar_api.setOption('locale', 'th');
      this.$i18n.locale === "cn" && this.calendar_api.setOption('locale', 'zh-cn');
      this.$i18n.locale === "vn" && this.calendar_api.setOption('locale', 'vi');
      this.$i18n.locale === "my" && this.calendar_api.setOption('locale', 'ms');
      this.$i18n.locale === "id" && this.calendar_api.setOption('locale', 'id');
    },
    checkFormat() { // Hidden content in Month format when title has long character
      return this.calendar_api?.currentData.currentViewType === "dayGridMonth" ? 'event-detail-hidden' : ''
    },
    checkHighLigthed(time_label) { // Highligthed current time on day format
      let temp_stay_date
      let current_date
      this.format(new Date(), "HH")
      if (this.temp_stay_format === 'timeGridDay') {
        current_date = moment(new Date()).format('YYYY-MM-DD').toString()
        temp_stay_date = this.temp_stay_date
        if (current_date === temp_stay_date) {
          if (time_label <= 9) {
            return `0${time_label}` === this.format(new Date(), "0H")
          } else {
            return `${time_label}` === this.format(new Date(), "HH")
          }
        }
      } else {
        return false
      }
    },
    formatTime(time_label) {   // Format time on header of day format calendar
      return time_label <= 9 ? `0${time_label}:00` : `${time_label}:00`
    },
    filterEventDayTime(start, time_label) { // Filter event to display in the correct time on day format
      return this.format(new Date(start), "HH:00") === time_label
    },
    setupCalendarLayout() {
      let body_width = document.querySelector('div.fc-daygrid-body.fc-daygrid-body-unbalanced')
      let header_calendar = document.querySelector('tr.fc-scrollgrid-section.fc-scrollgrid-section-header')
      let table_header_calendar = document.querySelector('table.fc-col-header')
      let table_body_width_height = document.querySelector('table.fc-scrollgrid-sync-table')
      let table_height = document.querySelector('div.fc-scroller.fc-scroller-liquid-absolute')
      table_body_width_height.style.width = `100%`
      table_body_width_height.style.height = table_height.offsetHeight.toString() + 'px'
      body_width.style.width = `100%`
      table_header_calendar.style.width = `100%`
      header_calendar.style.width = `100%`
      let grid_height = document.querySelectorAll('.fc-daygrid-day-frame.fc-scrollgrid-sync-inner')
      let calendar_date_title = document.querySelectorAll('div.fc-daygrid-day-top')
      if (screen.width > 576) {
        grid_height.forEach((e) => {
          e.style.height = `200px`
        })
        this.calendarOptions.dayMaxEvents = 3
      } else {
        calendar_date_title.forEach((e) => {
          e.style.fontSize = `14px`
        })
        grid_height.forEach((e) => {
          e.style.height = `100px`
        })
        this.calendarOptions.dayMaxEvents = 0
      }
    },
    navigateFilerByMonthAndYear() {
      let rotate_date = new Date(this.form_filter.select_filter_year, this.form_filter.select_filter_month + 1);
      this.calendar_api.changeView("dayGridMonth", rotate_date)
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignEvent(current_date)
      this.getAllCampaignStatus({
        month: current_date.getMonth() + 1,
        year: current_date.getFullYear(),
      })
      this.isToday()
    },
    getAllCampaignEvent(date) { // day format
      let date_from = moment(date).format('YYYY-MM-DD') + 'T00:00:00' + '.000Z'
      let date_to = moment(date).format('YYYY-MM-DD') + 'T23:59:59' + '.999Z'
      if (this.time_grid_day) {
        document.getElementById('calendar-opacity-loading-container-day').style.display = 'block'
        document.getElementById('loading-calendar-day').style.display = 'block'
      }
      calendarAPI.getCampaignAll(date_from, date_to).then((res) => {
        this.temp_event = res
      }).catch((e) => {
        throw e
      }).finally(() => {
        if (this.time_grid_day) {
          document.getElementById('calendar-opacity-loading-container-day').style.display = 'none'
          document.getElementById('loading-calendar-day').style.display = 'none'
          this.scrollToHighLigth()
        }
      })
    },
    getAllCampaignStatus(form) {
      calendarAPI.getCampaignStatus(form).then((res) => {
        let summary = res?.summary
        this.total_sending = summary.new ? summary.schedule ? summary.new + summary.schedule : summary.new : summary.schedule ? summary.schedule : 0
        this.total_pending = summary.processing ? summary.processing : 0
        this.total_sent = summary.delivered ? summary.delivered : 0
        this.total_cancelled = summary.cancelled ? summary.cancelled : 0
        this.total_all = summary.total ? summary.total : 0
      }).catch((e) => {
        throw e
      })
    },
    handleClickMoreLinkAndDate(clickInfo) {
      if (clickInfo.view.type === "dayGridMonth") {
        this.changeViewToDay(true, clickInfo.date)
      }
    },
    handleEventClick(clickInfo) {
      let temp_show_event = {
        campaign_id: clickInfo.event.id,
        campaign_name: clickInfo.event.title,
        start: this.format(new Date(clickInfo.event.start), "HH:mm"),
        total_contract: clickInfo.event.extendedProps.contact,
        total_credit: clickInfo.event.extendedProps.credit,
        status: clickInfo.event.extendedProps.status,
        campaign_type: clickInfo.event.extendedProps.campaign_type,
      }
      this.temp_show_event = temp_show_event
    },
    next() {
      this.calendar_api?.next();
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignEvent(current_date)
      this.filterCampaignStatus(current_date)
      this.form_filter.select_filter_month = masterData.monthList[current_date.getMonth()].value
      this.form_filter.select_filter_year = current_date.getFullYear()
      this.isToday()
    },
    prev() {
      this.calendar_api?.prev();
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignEvent(current_date)
      this.filterCampaignStatus(current_date)
      this.form_filter.select_filter_month = masterData.monthList[current_date.getMonth()].value
      this.form_filter.select_filter_year = current_date.getFullYear()
      this.isToday()
    },
    changeViewToDay(require, date) {
      this.time_grid_day = true
      require ? this.calendar_api?.changeView("timeGridDay", date) : this.calendar_api?.changeView("timeGridDay");
      this.focus_format_month = !this.focus_format_month;
      this.focus_format_day = !this.focus_format_day;
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignEvent(current_date)
      this.getAllCampaignStatus({
        date_from: moment(current_date).format('YYYY-MM-DD') + 'T00:00:00' + '.000Z',
        date_to: moment(current_date).format('YYYY-MM-DD') + 'T23:59:59' + '.999Z',
      })
      this.isToday()
    },
    changeViewToMonth() {
      this.focus_format_month = !this.focus_format_month
      this.focus_format_day = !this.focus_format_day
      this.time_grid_day = false
      this.calendar_api?.changeView("dayGridMonth")
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignStatus({
        month: current_date.getMonth() + 1,
        year: current_date.getFullYear(),
      })
      this.form_filter.select_filter_month = masterData.monthList[current_date.getMonth()].value
      this.form_filter.select_filter_year = current_date.getFullYear()
      this.isToday()
    },
    today() {
      this.calendar_api.today();
      this.formatCalendarTitle()
      let current_date = new Date(this.calendar_api?.currentData.currentDate)
      this.getAllCampaignEvent(current_date)
      this.filterCampaignStatus(current_date)
      this.form_filter.select_filter_month = masterData.monthList[current_date.getMonth()].value
      this.form_filter.select_filter_year = current_date.getFullYear()
      this.isToday()
    },
    filterCampaignStatus(current_date) {
      if (this.calendar_api?.currentData.currentViewType === "dayGridMonth") {
        this.getAllCampaignStatus({
          month: current_date.getMonth() + 1,
          year: current_date.getFullYear(),
        })
      } else {
        this.getAllCampaignStatus({
          date_from: moment(current_date).format('YYYY-MM-DD') + 'T00:00:00' + '.000Z',
          date_to: moment(current_date).format('YYYY-MM-DD') + 'T23:59:59' + '.999Z',
        })
      }
    },
    campaignDetail(data) {
      const query = {
        campaign: data?.id,
        time1: moment(data?.start).format('YYYY-MM-DD') + 'T00:00:00',
        time2: moment(data?.start).format('YYYY-MM-DD') + 'T23:59:59',
      }
      const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(query))
      const str = queryString.toString()
      this.$router.push(`/Reports/Campaigns/Detail?${str}`).catch(() => {
      })
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables/_variable";

span.custom-tooltip-icon {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--primary-color) !important;
}

.calendar-hidden-container, .calendar-hidden-container-day {
  display: none;
}

///// Calendar Container and loading animation //////
.calendar-block-container {
  position: relative;
  cursor: pointer;

  .calendar-opacity-loading-container, .calendar-opacity-loading-container-day {
    position: absolute;
    border-radius: 8px !important;
    background: #ffffff;
    opacity: 0.7;
    z-index: 998;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .calendar-opacity-loading-container-day {
    opacity: 0.9;
  }

  .loading-calendar, .loading-calendar-day {
    display: flex;
    align-items: center;
    z-index: 999;
    opacity: 1 !important;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

//////////// Calendar ///////////////////
#calendar__container-wrap {
  button.btn-no-outline {
    box-shadow: 0px 0px 0px transparent !important;
  }

  //scroll calendar
  div.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar {
    display: none !important;
  }

  //Calendar Day Format
  .time_grid_day-container {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    overflow-x: scroll;
    scroll-behavior: smooth;
    transition-duration: 500ms;

    .high-lighted {
      span {
        border-radius: 20px;
        padding: 4px 10px;
        color: white;
        background: var(--primary-color) !important;
        font-weight: 700 !important;
      }
    }

    .grid-header, .grid-content {
      background-color: white;
      border: 1px solid #E9E9E9;
      padding: 12px;
      font-size: 16px;
      font-family: 'Sukhumvit Set';
      font-weight: 500;
      color: var(--secondary-color);
      width: 280px;
      text-align: center;
      border-top: transparent;
      border-bottom: transparent;
      border-left: transparent;
    }

    .grid-header:nth-child(24) {
      border-right: transparent;
    }


    .grid-content {

      .grid-content__row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 18px;

        .event-day {
          display: flex;
          justify-content: flex-start;
          border-radius: 10px;
          flex-direction: column;
          width: 100%;
          padding: 16px;
          gap: 8px;
          border: 1px solid #E9E9E9;

          div.event-day__row:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            span {
              font-family: 'Sukhumvit Set';
              font-weight: 600;
              font-size: 16px;
            }
          }

          .event-day__row {
            .hidden-title-event-day {
              overflow: hidden;
              max-width: 100px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            padding: 0;

            span {
              font-family: 'Sukhumvit Set';
              font-weight: 500;
              font-size: 14px;
            }

            div {
              display: flex;
              align-items: center;
              gap: 8px;

              p {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .event-day:hover {
    background: #fafafa !important
  }

  .event-day:hover {
    background: #fafafa !important
  }

  //Calendar Month Format
  .full-calendar {
    background-color: $white;
    border-radius: 20px;
    padding: 16px;

    //calendar nav
    .full-calendar__calendar-nav {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 16px;
      gap: 16px;
      width: 100%;

      .calendar-status__layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        gap: 2px;
        @media (max-width: 576px) {
          gap: 4px;
        }

        .calendar-status__status-total {
          display: flex;
          align-items: center;
          gap: 8px;

          .total-campaign {
            color: var(--primary-color);
            font-weight: 700;
          }

          span {
            font-size: 16px;
          }
        }
      }

      .nav-calendar__filter-calendar,
      .filter-calendar__left,
      .filter-calendar__right,
      .filter-calendar__right-day,
      .nav-calendar__calendar-status,
      .calendar-status,
      .calendar-status__status {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .calendar-status__layout-layout {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 768px) {
            overflow: scroll;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .report-campaign {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        .calendar-status {
          gap: 16px;
          @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
          }

          .calendar-status__status {
            gap: 4px;
            align-items: center;
            justify-content: flex-start !important;
            @media (max-width: 768px) {
              white-space: nowrap;
            }

            .total-campaign {
              color: var(--primary-color);
              font-weight: 700;
            }

            span {
              font-size: 16px;
            }

          }
        }

        .filter-calendar__right {
          gap: 16px;
          @media (max-width: 576px) {
            flex-direction: column;
            width: 100%;
            gap: 0px !important;
          }
        }

        .filter-calendar__left {
          gap: 16px;
          flex-direction: row !important;
          border-radius: 20px;
          padding: 8px 12px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
          @media (max-width: 576px) {
            width: 100%;
            justify-content: center !important;
          }
        }

        .add-new-campaign {
          @media (max-width: 576px) {
            width: 100% !important;
          }
        }

        .filter-calendar {
          display: flex;
          align-items: center;
          gap: 8px !important;
        }
      }

      .nav-calendar__filter-calendar {
        @media (max-width: 576px) {
          flex-direction: column;
          gap: 12px;
        }
      }

      .filter-calendar__right-day {
        justify-content: flex-end !important;
      }

      .nav-calendar__calendar-operate,
      .calendar-prev-next {
        display: flex;
        align-items: center;
        gap: 24px;
        @media (max-width: 576px) {
          justify-content: space-between;
        }

        .current-date-sm {
          display: none;
          font-size: 18px;
          font-weight: $font-weight-medium;
          @media (max-width: 576px) {
            display: block;
          }
        }

        .current-date {
          font-size: 24px;
          font-weight: $font-weight-medium;
          @media (max-width: 576px) {
            display: none;
          }
        }

        .calendar-prev-next {
          gap: 48px;
          @media (max-width: 576px) {
            gap: 18px;
          }

          .calendar-next,
          .calendar-prev {
            width: 12px;
            height: 12px;
            background-color: var(--primary-color);
            cursor: pointer;
          }
        }
      }
    }
  }

  //calendar event detail
  .event-detail {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 24px;
    margin: 4px 16px;
    cursor: pointer;
  }

  .event-detail-hidden {
    overflow: hidden;
  }

  /// Custom Lib ///
  // change highlight click date
  div.fc-highlight {
    background: var(--primary-color) !important;
    opacity: 0.2 !important;
  }

  // add more event font
  a.fc-daygrid-more-link.fc-more-link {
    font-size: 14px;
    @media (max-width: 576px) {
      font-size: 8px !important;
    }
  }

  // display flex for small device
  .fc-daygrid-day-events {
    @media (max-width: 576px) {
      display: flex !important;
      justify-content: center !important;
    }
  }

  // remove padding margin on each event and more event
  .fc-daygrid-day-events, .fc-daygrid-day-bottom {
    margin: 0 !important;
    padding: 0 !important;

    div.fc-daygrid-event-harness a {
      margin: 0 !important;
      padding: 0 !important;

      &:hover {
        background-color: inherit !important;
      }
    }

    &:hover {
      background-color: inherit !important;
    }
  }

  //padding each date in calendar
  .fc-daygrid-day-top {
    padding: 12px 12px 6px 12px !important;
  }

  //adjust more event
  a.fc-daygrid-more-link.fc-more-link {
    text-align: left;
    height: 24px;
    margin: 0 16px;
    margin-bottom: 16px;
    padding: 0px !important;
    @media (max-width: 576px) {
      margin: 0px !important;
      height: 12px;
    }
  }

  //remove background current day
  .fc-day-today {
    background-color: inherit !important;

    .fc-daygrid-day-top a {
      background-color: var(--primary-color) !important;
      border-radius: 50% !important;
      padding: 4px;
      width: 40px;
      height: 38px;
      text-align: center;
      color: $white !important;
      @media (max-width: 576px) {
        padding: 4px;
        width: 24px;
        height: 28px;
      }
    }
  }

  //remove color in event card in month format
  .fc-daygrid-event-harness {
    a.fc-event {
      justify-content: center;
      align-items: center;
      display: flex;
      background: transparent;
      border: 0px;
    }
  }

  //remove hover day
  a.fc-daygrid-dot-event {
    :hover {
      background: transparent;
    }
  }

  //remove text decoration
  a.fc-daygrid-day-number,
  a.fc-col-header-cell-cushion,
  a.fc-daygrid-more-link.fc-more-link {
    text-decoration: none !important;
    color: #9f9f9f;
  }

  // reduce font of header calendar
  a.fc-col-header-cell-cushion {
    @media (max-width: 576px) {
      font-size: 14px !important;
    }
  }

  //extend day header
  th.fc-col-header-cell.fc-day {
    padding: 12px;
    text-align: left !important;
    font-size: 16px;
    @media (max-width: 576px) {
      text-align: center !important;
      padding: 0;
    }
  }

  //adjust date text to left side
  div.fc-daygrid-day-top {
    padding: 12px;
    font-size: 20px;
    flex-direction: row !important;
  }

  //table border month radius
  .fc-theme-standard .fc-dayGridMonth-view.fc-view.fc-daygrid table {
    border-radius: 8px !important;
  }

  //fix conflict month border
  .fc-theme-standard .fc-dayGridMonth-view.fc-view.fc-daygrid td {
    border-radius: 0 0 8px 8px !important;
  }

  //fix conflict month border
  .fc-theme-standard .fc-dayGridMonth-view.fc-view.fc-daygrid th {
    border-radius: 0 8px 0 0 !important;
  }

  //disable click
  td.fc-day-disabled {
    background: inherit !important;
    cursor: not-allowed !important;
  }
}

//////////// Tooltip /////////////////
//Tooltip Month Format
#calendar__container-wrap .fc-daygrid-day-events {
  // tooltip content
  .event-detail div {
    width: 100% !important;

    span.custom-tooltip-content {
      display: flex;
      padding: 1px 10px;
      align-items: center;
      gap: 6px;
      width: 100% !important;
      border-radius: 4px;
      background: #F6F6F6 !important;
      margin: 0px !important;
      border: transparent;
      cursor: pointer !important;

      &:hover {
        background: #c6c8cd !important;
      }

      span {
        color: #2F2E2E;
        font-size: 13px !important;
        font-weight: 600 !important;
      }

      span:nth-child(3) {
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// tooltip popper content
.tippy-popper {

  div.tippy-tooltip.light-theme {
    border-radius: 20px;
    box-shadow: var(--primary-color) 0px 2px 6px 0px !important;

    .tippy-content div div.icon-status-container {
      display: flex;
      flex-direction: column;
      gap: 16px !important;
      width: 320px !important;
      padding: 32px !important;

      div {
        display: flex;
        gap: 16px;
        justify-content: flex-start;
        align-items: center;
        margin: 0px !important;
        padding: 0px !important;

        .fix-icon-size {
          width: 30px !important;

          span.custom-tooltip-icon {
            width: 100% !important;
            height: 24px !important;
            background-color: var(--primary-color) !important;
          }
        }

        .fix-icon-paragraph {
          width: calc(100% - 46px);
          display: flex;

          p, span {
            display: flex;
            gap: 5px;
            padding: 0 !important;
            margin: 0 !important;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
          }
        }
      }

      div:last-child {
        .fix-icon-paragraph {
          span {
            border-radius: 4px;
            padding: 2px 12px !important;
            background: #F6F6F6 !important;
            border: transparent;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}

// tooltip boostrap
.tooltip.b-tooltip.bs-tooltip-top {

  .arrow::before {
    border-top-color: var(--primary-color) !important;
  }

  .tooltip-inner {
    max-width: 90vw;
    background: var(--primary-color) !important;
  }

}

</style>