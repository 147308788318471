<template>
  <b-modal
      id="modal-create-contact"
      size="xl"
      ok-variant="info"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('contacts.create.createContact') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="onComplete()"></i>
    </template>
    <b-card no-body class="border">
      <b-tabs card>
        <b-tab :title="$t('contacts.create.importData')" active>
          <import-file-contact :group_id="group_id" @output-import="onComplete"/>
        </b-tab>
        <b-tab :title="$t('contacts.create.pasteText')">
          <b-card-text>
            <div>
              <div class="mb-4">
                <input-text-area-component
                    :label-input="$t('contacts.create.pasteText')"
                    rows="5"
                    v-model="$v.form.text_phones.$model"
                    @focusout="replaceManualInput(form.text_phones)"
                    :state="validateState('text_phones') && !telInvalid"
                    require
                    :require-label="
              validateState('text_phones') !== null &&
              !$v.form.text_phones.required
                ? $t('common.requiredField')
                : telInvalid
                ? telInvalidString
                : ''
            "
                />
                <label class="label-for-input text-secondary mt-3">
                  {{ $t('contacts.blacklist.create.chooseDelimiter') }}
                </label>
                <div class="mb-3">
                  <b-button-group class="m-2">
                    <b-button v-for="(item, index) in options"
                              :key="index"
                              @click="delimiterType(item.value)"
                              :variant="item.value === form.type ? 'primary' : 'outline'">
                      {{ item.text }}
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </div>
            <div v-html="$t('contacts.create.note')"/>
            <b-row align-h="end" class="mr-1 mt-3">
              <b-button
                  variant="warning"
                  class="mr-2"
                  :disabled="$v.form.text_phones.$invalid"
                  @click="resetForm"
              >
                <i class="fas fa-retweet"/>
                {{ $t('common.resetSearch') }}
              </b-button>
              <b-button
                  variant="primary"
                  :disabled="$v.form.$invalid || is_loading"
                  @click="createByText"
              >
                <i class="fas fa-save"/>
                {{ $t('button.save') }}
                <b-spinner small v-if="is_loading"/>
              </b-button>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab :title="$t('contacts.create.manualInput')">
          <b-card-text>
            <manual-create-contact :group_id="group_id" @output="onComplete"/>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import ManualCreateContact from "@/views/contacts/contact/manualCreate";
import ImportFileContact from "@/views/contacts/contact/importFile";
import smsTemplatesApi from "@/repository/smsTemplatesApi";
import alert from "@/common/alert";
import contactApi from "@/repository/contactApi";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import functionCommon from "@/common/functions";

export default {
  name: "createContact",
  props: ['group_id'],
  components: {
    ImportFileContact,
    ManualCreateContact,
    SelectComponent, InputComponent, InputTextAreaComponent, UploadFileComponent
  },
  mixins: [validationMixin],
  data() {
    return {
      manualCount: 0,
      options: [
        {value: "comma", text: ', ' + this.$t('symbol.comma')},
        {value: "semicolon", text: '; ' + this.$t('symbol.semicolon')},
        {value: "bar", text: '| ' + this.$t('symbol.bar')},
        {value: "tab", text: this.$t('symbol.tab')},
        {value: "new_line", text: this.$t('symbol.newLine')},
      ],
      is_loading: false,
      form: {
        contact_group_id: this.group_id,
        text_phones: '',
        type: "comma",
      },
    }
  },
  validations: {
    form: {
      text_phones: {
        required,
      },
      type: {
        required,
      },
      contact_group_id: {
        required,
      },
    },
  },
  mounted() {
    this.findTag()
  },
  computed: {
    telInvalid() {
      let spliter = ''
      if (this.form.type === 'comma') spliter = ','
      else if (this.form.type === 'semicolon') spliter = ';'
      else if (this.form.type === 'tab') spliter = ' '
      else if (this.form.type === 'bar') spliter = '|'
      else if (this.form.type === 'new_line') spliter = '\n'

      let telNum = _.split(this.form.text_phones, spliter)
      let isInvalid = false
      telNum.forEach((number) => {
        let n = _.trim(number)
        if (!this.checkNumber(n)) {
          isInvalid = true
        }
      })
      return isInvalid
    },
    telInvalidString() {
      const reg = /^[0-9]*((-|\s)*[0-9])*$/g
      let spliter = ''
      if (this.form.type === 'comma') spliter = ','
      else if (this.form.type === 'semicolon') spliter = ';'
      else if (this.form.type === 'tab') spliter = ' '
      else if (this.form.type === 'bar') spliter = '|'
      else if (this.form.type === 'new_line') spliter = '\n'
      let telNum = _.split(this.form.text_phones, spliter)
      let alertString = ''
      telNum.forEach((number) => {
        let n = _.trim(number)
        if (n.length !== 0) {
          if (!n.match(reg)) alertString = this.$t('common.telNumOnly')
          if (n.charAt(0) !== '0' && n.charAt(0) !== '6') {
            alertString = this.$t('common.telStart')
          } else if ((n.charAt(1) !== '6' && n.charAt(1) !== '8' && n.charAt(1) !== '9')) {
            alertString = this.$t('common.telStart')
          } else if (n.length < 10) {
            alertString = this.$t('common.telLength')
          } else if (n.length === 11 && (n.charAt(0) !== '6' && n.charAt(1) !== '6')) {
            alertString = this.$t('common.telStart66')
          } else if ((n.charAt(0) === '6' && n.charAt(1) === '6') && n.charAt(2) !== '6' && n.charAt(2) !== '8' && n.charAt(2) !== '9') {
            alertString = this.$t('common.telFormat')
          }
        }
      })
      return alertString
    },
  },
  methods: {
    checkNumber(number) {
      return functionCommon.telState(number)
    },
    replaceManualInput(msg) {
      let regExp = /\d+/g;
      if (regExp.test(msg)) {
        let trim = msg.trim()
        let nameArr = trim.match(regExp);
        let findDuplicate = nameArr.filter(function (value, index, array) {
          return array.indexOf(value) === index && value !== '';
        })
        let delimiter = ''
        if (this.form.type === 'comma') delimiter = ','
        else if (this.form.type === 'semicolon') delimiter = ';'
        else if (this.form.type === 'bar') delimiter = '|'
        else if (this.form.type === 'tab') delimiter = ' '
        else if (this.form.type === 'new_line') delimiter = '\n'
        this.manualCount = findDuplicate?.length
        this.form.text_phones = findDuplicate.join(delimiter)
      } else {
        this.form.text_phones = ''
        this.manualCount = 0
      }
    },

    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    delimiterType(e) {
      this.form.type = e
    },
    onComplete() {
      this.$emit('output', 'success');
      this.$bvModal.hide('modal-create-contact')
    },
    findTag() {
      smsTemplatesApi.getTag().then((response) => {
        this.$store.dispatch("template/setHeaderList", response.data);
      })
          .catch((err) => {
            alert.findFailed(this, err.response.data.message);
          });
    },
    resetForm() {
      this.form.text_phones = ''
      this.form.type = 'comma'
      this.$v.form.$reset()
    },
    clearData() {
      this.onComplete()
      this.resetForm()
    },
    createByText() {
      this.is_loading = true
      contactApi.createByText(this.form).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.is_loading = false
      })
    }
  },
  watch: {
    'form.type'(val) {
      if (val) {
        this.replaceManualInput(this.form.text_phones)
      }
    }
  },
}
</script>

<style scoped>


</style>