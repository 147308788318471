<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col lg="6" md="3" sm="4">
                <b-form-group
                    label-cols="12"
                    :label="$t('contacts.create.phone')"
                    label-for="input-sm"
                >
                  <b-form-input v-model="form.phone"/>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="3" sm="4">
                <b-form-group
                    label-cols="12"
                    :label="$t('contacts.groupList.name')"
                    label-for="input-sm"
                >
                  <b-form-input v-model="form.name"/>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="3" sm="4">
                <select-filter-component
                    :label-select="$t('contacts.groupList.status')"
                    :options="statusList"
                    v-model="form.status"
                />
              </b-col>
              <b-col style="margin-top: 35px">
                <button
                    type="button"
                    class="btn waves-effect waves-light btn-gradient"
                    @click="findContact"
                >
                  {{ $t("common.Search") }}
                </button>
                <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
                  {{ $t('common.resetSearch') }}
                </button>
              </b-col>
            </b-row>
            <hr/>
            <b-row align-v="center" class="pb-3">
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="mr-3">{{$t('common.perPage')}}</span>
                  <div>
                    <b-form-select
                        v-model="form.$limit"
                        :options="pageOptions"
                        size="sm"
                        @change="findContact"
                    />
                  </div>
                </div>
              </b-col>
              <b-col align="end" class="mb-2">
                <b-button variant="primary" class="mr-1" @click="exportContact"
                          v-if="checkPermission('export', 'action', 'contacts')">
                  <i class="fas fa-file-excel "></i>
                  {{ $t("button.export") }}
                </b-button>
                <b-button variant="gradient" class="mr-1" v-b-modal.modal-create-contact
                          :disabled="process==='PROCESS' || process==='QUEUE'"
                          v-if="checkPermission('create', 'action', 'contacts')"><i class="fas fa-plus-circle "></i>
                  {{ $t("button.addNew") }}
                </b-button>
              </b-col>
            </b-row>
            <div v-if="process==='PROCESS' || process==='QUEUE'" align="center">
              <b-spinner
                  class="mt-5 mb-1"
                  style="width: 4rem; height: 4rem"
                  label="Large Spinner"
                  variant="warning"
                  type="grow">
              </b-spinner>
              <h3 v-html="$t('processing')" class="mb-3"></h3>
            </div>
            <div v-else>
              <b-table
                  responsive
                  class="mb-0"
                  :items="items"
                  :fields="fields"
                  :busy="isBusy"
                  :empty-text="$t('empty')"
                  show-empty
              >
                <template #head()="{ label, field: { key, sortable }}">
                  {{ $t(label) }}
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong> {{ $t('loading') }}</strong>
                  </div>
                </template>
                <template #empty="scope">
                  <empty-table/>
                </template>
                <template #cell(name)="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
                <template #cell(createdAt)="data">
                  {{ changeDateFormat(data.item.createdAt) }}
                </template>
                <template #cell(status)="data">
                  <switch-component v-model="data.item.status"
                                    @input="activeStatus(data.item._id,data.item.status)"
                                    v-if="checkPermission('update-status', 'action', 'contacts')"/>
                </template>
                <template #cell(actions)="data">
                  <i style="color: #0d73d9" class="action-button far fa-paper-plane mr-2"
                     @click="sendToQuickSend(data.item.phone)"
                     v-if="checkPermission('quick-send', 'tab')"></i>
                  <i style="color: #7367f0" class="action-button icon-note mr-2" @click="viewContact(data.item._id)"
                     v-if="checkPermission('update-info', 'action', 'contacts')"/>
                  <i style="color: #DF3F1E" class="action-button icon-trash " @click="deleteContact(data.item._id)"
                     v-if="checkPermission('delete', 'action', 'contacts')"/>
                </template>
              </b-table>
            </div>
          </b-col>
          <div class="ml-auto mt-4">
            <b-pagination
                v-model="form.$skip"
                :total-rows="totalRows"
                :per-page="form.$limit"
                align="fill"
                class="my-0"
                size="sm"
                @input="findContact"
            />
          </div>
          <view-contact :contact_id="contact_id" @output="findContact"/>
        </b-row>
      </b-card>
    </b-col>
    <create-contact :group_id="group_id" @output="findContact"/>
    <b-modal
        id="modal-export"
        ok-variant="info"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div align="center" v-if="!exportSuccess">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
      <div v-if="exportSuccess">
        <div align="center" v-if="exportSuccess&&step_success">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
          <div v-for="items in fileExport">
            <a :href="items">{{ items }}</a>
            <hr/>
          </div>
        </div>
        <div align="center" v-if="!exportSuccess&&step_success">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import CreateContact from "@/views/contacts/contact/create";
import ManualCreateContact from "@/views/contacts/contact/manualCreate";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import SwitchComponent from "@/components/common/switchComponent";
import ViewContact from "@/views/contacts/contact/view";
import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";
import contactApi from "@/repository/contactApi";
import _ from "lodash";
import alert from "@/common/alert";
import functions from "@/common/functions";
import {validatePhoneNumber} from "@sms2pro/sms2pro"
import EmptyTable from "@/components/common/emptyTable.vue";

export default {
  name: "contact",
  components: {EmptyTable, SelectFilterComponent, SwitchComponent, ViewContact, ManualCreateContact, CreateContact},
  props: ['group_id', 'process'],
  data: () => ({
    form: {
      contact_group_id: null,
      name: '',
      phone: '',
      status: null,
      $skip: 1,
      $limit: 10,
    },
    exportSuccess: false,
    step_success: false,
    contact_id: '',
    fileExport: [],
    isBusy: false,
    fields: [
      {
        key: "phone",
        label: "contacts.groupList.contacts",
      },
      {
        key: "name",
        label: "contacts.groupList.name",
      },
      {
        key: "createdAt",
        label: "contacts.groupList.createdAt",
      },
      {
        key: "status",
        label: "report.campaignList.contactList.status",
      },
      {
        key: "actions",
        label: "report.campaignList.contactList.actions",
      },
    ],
    statusList: masterData.activeList,
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  methods: {
    validatePhoneNumber(data) {
      const phone = data ? validatePhoneNumber(data) : ''
      return phone ? phone : data
    },
    exportContact() {
      this.$bvModal.show('modal-export')
      const data = {
        contact_group_id: this.group_id,
        file_type: "csv"
      }
      contactApi.exportContact(data).then(response => {
        if (response?.codeSYS === '001') {
          this.fileExport = response?.url_files
          this.exportSuccess = true
          this.step_success = true
        } else {
          this.exportSuccess = false
          this.step_success = false
        }
      }).catch(err => {
        this.$bvModal.hide('modal-export')
        alert.findFailed(this, err.response.data.message)
      })
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    viewContact(id) {
      this.contact_id = id
      this.$bvModal.show('modal-view-contact')
    },
    findContact() {
      this.isBusy = !this.isBusy
      this.$emit('output', 'success');
      this.form.contact_group_id = this.group_id
      this.form.phone = this.validatePhoneNumber(this.form.phone)
      contactApi.findContactAll(_.cloneDeep(this.form)).then(response => {
        this.totalRows = response.total
        this.items = response.data
      }).finally(() => {
        this.isBusy = !this.isBusy
        this.$emit('output', true)
      })
    },
    activeStatus(id) {
      contactApi.activeStatus(id).then(response => {
        if (response?.codeSYS === '001') {
          alert.updateSuccess(this)
        } else {
          alert.updateFailed(this, response.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err.response.data.message)
      }).finally(() => {
        this.$emit('output', true)
      })
    },
    sendToQuickSend(phone) {
      this.$router.push(`/SMS?recipient=${phone}`).catch(() => {
      })
    },
    resetSearch() {
      this.form = {
        phone: '',
        name: '',
        status: null,
        $skip: 1,
        $limit: 10,
      }
      this.findContact()
    },
    deleteContact(id) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('common.delete.topic'),
        text: this.$t('common.delete.detail'),
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.yes'),
      })
          .then((result) => {
            if (result.isConfirmed) {
              contactApi.delete(id).then((response) => {
                if (response?.codeSYS === '001') {
                  alert.deleteSuccess(this)
                  this.findContact()
                } else {
                  alert.deleteFailed(this, response.message)
                }
              })
                  .catch((err) => {
                    alert.deleteFailed(this, err.response.data.message)
                  })
            }
          })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  },
  mounted() {
    this.findContact()
  },
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
